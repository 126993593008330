import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import quizIcon from "../../images/quiz-icon.svg";
import logo from "../../images/logo.png";

const QuizDetails = () => {
    // Fetch Quiz
    const quizDb = require("../../quizDb/quizDb.json");
    const { quizId } = useParams();
    const [quiz, setQuiz] = useState({});

    useEffect(() => {
        const searchQuiz = quizDb.filter((quiz) => quiz.id == quizId)[0];
        setQuiz(searchQuiz);
    }, [quizId]);

    // Result
    const [total, setTotal] = useState(0);
    const [activeQuestion, setActiveQuestion] = useState(1);

    const handleTotal = (mark) => {
        setTotal((prevState) => {
            return prevState + mark;
        });

        setActiveQuestion((prevState) => {
            return prevState + 1;
        });
    };

    const resetQuiz = () => {
        setTotal(0);
        setActiveQuestion(1);
    };

    let finalResult;

    if (quiz?.id) {
        if (total > 0 && total <= quiz?.results[0]?.mark) {
            finalResult = (
                <div className="relative">
                    <img
                        className="w-full lg:w-full mx-auto rounded-2xl"
                        src={quiz?.results[0]?.img}
                        alt="Dhakar Pola"
                    />

                    <div
                        className="w-full top-0 bottom-0 left-0 right-0 m-auto p-5 rounded-2xl mt-2"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}
                    >
                        <div className="font-extrabold tracking-tight leading-none text-center">
                            <h4 className="text-sm md:text-3xl xl:text-2xl text-white mb-0 md:mb-2">
                                আপনি
                            </h4>
                            <h1 className="text-base md:text-5xl xl:text-4xl text-primary-700">
                                {quiz?.results[0]?.title}
                            </h1>
                        </div>
                    </div>
                </div>
            );
        } else if (
            total > quiz?.results[0]?.mark &&
            total <= quiz?.results[1]?.mark
        ) {
            finalResult = (
                <div className="relative">
                    <img
                        className="w-full md:w-3/5 lg:w-3/5 mx-auto rounded-2xl"
                        src={quiz?.results[1]?.img}
                        alt="Dhakar Pola"
                    />

                    <div
                        className="w-full top-0 bottom-0 left-0 right-0 m-auto p-5 rounded-2xl mt-2"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}
                    >
                        <div className="font-extrabold tracking-tight leading-none text-center">
                            <h4 className="text-sm md:text-3xl xl:text-2xl text-white mb-0 md:mb-2">
                                আপনি
                            </h4>
                            <h1 className="text-base md:text-5xl xl:text-4xl text-primary-700">
                                {quiz?.results[1]?.title}
                            </h1>
                        </div>
                    </div>
                </div>
            );
        } else if (
            total > quiz?.results[1]?.mark &&
            total <= quiz?.results[2]?.mark
        ) {
            finalResult = (
                <div className="relative">
                    <img
                        className="w-full lg:w-full mx-auto rounded-2xl"
                        src={quiz?.results[2]?.img}
                        alt="Dhakar Pola"
                    />

                    <div
                        className="w-full top-0 bottom-0 left-0 right-0 m-auto p-5 rounded-2xl mt-2"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}
                    >
                        <div className="font-extrabold tracking-tight leading-none text-center">
                            <h4 className="text-sm md:text-3xl xl:text-2xl text-white mb-0 md:mb-2">
                                আপনি
                            </h4>
                            <h1 className="text-base md:text-5xl xl:text-4xl text-primary-700">
                                {quiz?.results[2]?.title}
                            </h1>
                        </div>
                    </div>
                </div>
            );
        } else if (
            total > quiz?.results[2]?.mark &&
            total <= quiz?.results[3]?.mark
        ) {
            finalResult = (
                <div className="relative">
                    <img
                        className="w-full lg:w-full mx-auto rounded-2xl"
                        src={quiz?.results[3]?.img}
                        alt="Dhakar Pola"
                    />

                    <div
                        className="w-full top-0 bottom-0 left-0 right-0 m-auto p-5 rounded-2xl mt-2"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}
                    >
                        <div className="font-extrabold tracking-tight leading-none text-center">
                            <h4 className="text-sm md:text-3xl xl:text-2xl text-white mb-0 md:mb-2">
                                আপনি
                            </h4>
                            <h1 className="text-base md:text-5xl xl:text-4xl text-primary-700">
                                {quiz?.results[3]?.title}
                            </h1>
                        </div>
                    </div>
                </div>
            );
        } else if (
            total > quiz?.results[3]?.mark &&
            total <= quiz?.results[4]?.mark
        ) {
            finalResult = (
                <div className="relative">
                    <img
                        className="w-full lg:w-full mx-auto rounded-2xl"
                        src={quiz?.results[4]?.img}
                        alt="Dhakar Pola"
                    />

                    <div
                        className="w-full top-0 bottom-0 left-0 right-0 m-auto p-5 rounded-2xl mt-2"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}
                    >
                        <div className="font-extrabold tracking-tight leading-none text-center">
                            <h4 className="text-sm md:text-3xl xl:text-2xl text-white mb-0 md:mb-2">
                                আপনি
                            </h4>
                            <h1 className="text-base md:text-5xl xl:text-4xl text-primary-700">
                                {quiz?.results[4]?.title}
                            </h1>
                        </div>
                    </div>
                </div>
            );
        } else if (
            total > quiz?.results[4]?.mark &&
            total <= quiz?.results[5]?.mark
        ) {
            finalResult = (
                <div className="relative">
                    <img
                        className="w-full lg:w-full mx-auto rounded-2xl"
                        src={quiz?.results[5]?.img}
                        alt="Dhakar Pola"
                    />

                    <div
                        className="w-full top-0 bottom-0 left-0 right-0 m-auto p-5 rounded-2xl mt-2"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}
                    >
                        <div className="font-extrabold tracking-tight leading-none text-center">
                            <h4 className="text-sm md:text-3xl xl:text-2xl text-white mb-0 md:mb-2">
                                আপনি
                            </h4>
                            <h1 className="text-base md:text-5xl xl:text-4xl text-primary-700">
                                {quiz?.results[5]?.title}
                            </h1>
                        </div>
                    </div>
                </div>
            );
        } else if (
            total > quiz?.results[5]?.mark &&
            total <= quiz?.results[6]?.mark
        ) {
            finalResult = (
                <div className="relative">
                    <img
                        className="w-full lg:w-full mx-auto rounded-2xl"
                        src={quiz?.results[5]?.img}
                        alt="Dhakar Pola"
                    />

                    <div
                        className="w-full top-0 bottom-0 left-0 right-0 m-auto p-5 rounded-2xl mt-2"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}
                    >
                        <div className="font-extrabold tracking-tight leading-none text-center">
                            <h4 className="text-sm md:text-3xl xl:text-2xl text-white mb-0 md:mb-2">
                                আপনি
                            </h4>
                            <h1 className="text-base md:text-5xl xl:text-4xl text-primary-700">
                                {quiz?.results[6]?.title}
                            </h1>
                        </div>
                    </div>
                </div>
            );
        } else if (
            total > quiz?.results[6]?.mark &&
            total <= quiz?.results[7]?.mark
        ) {
            finalResult = (
                <div className="relative">
                    <img
                        className="w-full lg:w-full mx-auto rounded-2xl"
                        src={quiz?.results[5]?.img}
                        alt="Dhakar Pola"
                    />

                    <div
                        className="w-full top-0 bottom-0 left-0 right-0 m-auto p-5 rounded-2xl mt-2"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}
                    >
                        <div className="font-extrabold tracking-tight leading-none text-center">
                            <h4 className="text-sm md:text-3xl xl:text-2xl text-white mb-0 md:mb-2">
                                আপনি
                            </h4>
                            <h1 className="text-base md:text-5xl xl:text-4xl text-primary-700">
                                {quiz?.results[7]?.title}
                            </h1>
                        </div>
                    </div>
                </div>
            );
        } else if (
            total > quiz?.results[7]?.mark &&
            total <= quiz?.results[8]?.mark
        ) {
            finalResult = (
                <div className="relative">
                    <img
                        className="w-full lg:w-full mx-auto rounded-2xl"
                        src={quiz?.results[5]?.img}
                        alt="Dhakar Pola"
                    />

                    <div
                        className="w-full top-0 bottom-0 left-0 right-0 m-auto p-5 rounded-2xl mt-2"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}
                    >
                        <div className="font-extrabold tracking-tight leading-none text-center">
                            <h4 className="text-sm md:text-3xl xl:text-2xl text-white mb-0 md:mb-2">
                                আপনি
                            </h4>
                            <h1 className="text-base md:text-5xl xl:text-4xl text-primary-700">
                                {quiz?.results[8]?.title}
                            </h1>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <>
            {/* For Web */}
            <section className="hidden lg:flex bg-secondary-700 h-screen justify-center items-center bangla-font">
                <div
                    className={`bg-white rounded-2xl p-20 relative quiz-cover-img`}
                >
                    {/* Logo */}
                    <div className="absolute top-[-55px] right-[-30px] z-20">
                        <Link to="/">
                            <img
                                className="w-28 mx-auto"
                                src={logo}
                                alt="Dhakar Pola"
                            />
                        </Link>
                    </div>

                    {/* Question */}
                    {quiz?.questions?.map((question) => {
                        return (
                            <div
                                key={question.id}
                                className={`${
                                    activeQuestion === question.id
                                        ? "block"
                                        : "hidden"
                                } transition-all duration-200`}
                            >
                                <h1
                                    className="font-extrabold tracking-tight text-4xl md:text-5xl xl:text-7xl max-w-3xl"
                                    style={{ lineHeight: "100px" }}
                                >
                                    {question.title}
                                </h1>

                                <div className="flex items-center justify-center">
                                    {/* Answers */}
                                    <div className="col-span-6 space-y-5">
                                        {question?.options?.map((option) => {
                                            return (
                                                <div
                                                    key={Math.random()}
                                                    onClick={() =>
                                                        handleTotal(
                                                            option?.mark
                                                        )
                                                    }
                                                    className="grid grid-cols-12 gap-2 answer"
                                                >
                                                    <div className="col-span-2 bg-gray-400 text-white font-extrabold tracking-tight leading-none text-xl md:text-2xl rounded-lg p-3 md:p-5 text-center transition-all duration-300 cursor-pointer option">
                                                        {option?.serial}
                                                    </div>

                                                    <div className="col-span-10 bg-gray-400 text-white font-extrabold tracking-tight leading-none text-xl md:text-2xl rounded-lg p-3 md:p-5 text-center transition-all duration-300 cursor-pointer option">
                                                        {option?.title}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>

                                    {/* Icon */}
                                    <div className="col-span-6">
                                        <img
                                            className="w-full"
                                            src={quizIcon}
                                            alt="Dhakar Pola"
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                    {/* Count */}
                    {activeQuestion <= quiz?.questions?.length && (
                        <div className="bg-primary-700 text-white rounded-full font-extrabold tracking-tight leading-none text-2xl p-5 text-center w-24 h-24 flex items-center justify-center absolute right-[-40px] bottom-[-40px]">
                            <div>
                                <span className="text-4xl">
                                    {activeQuestion}
                                </span>

                                <span className="font-normal">/</span>

                                <span>{quiz?.questions?.length}</span>
                            </div>
                        </div>
                    )}

                    {/* Final Result */}
                    {activeQuestion > quiz?.questions?.length && (
                        <>
                            {finalResult}
                            <div className="flex items-center justify-between mt-5">
                                <div>
                                    <button
                                        onClick={resetQuiz}
                                        type="button"
                                        className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-3 focus:outline-none"
                                    >
                                        Play again
                                    </button>
                                </div>

                                <div
                                    className="fb-share-button"
                                    data-href="https://dhakarpola.com/#/quiz"
                                    data-layout=""
                                    data-size=""
                                >
                                    <a
                                        target="_blank"
                                        href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdhakarpola.com%2F%23%2Fquiz&amp;src=sdkpreparse"
                                        className="fb-xfbml-parse-ignore text-white bg-[#1877F2] font-medium text-sm py-1 px-2 rounded flex items-center"
                                    >
                                        <i className="fa-brands fa-square-facebook text-xl mr-1"></i>
                                        Share
                                    </a>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </section>

            {/* For Tab And Mobile */}
            <section className="flex lg:hidden h-screen items-center justify-center bg-secondary-700 p-5 bangla-font">
                <div className="bg-white rounded-2xl px-5 py-10 relative">
                    {/* Logo */}
                    <div className="mb-5 md:mb-10">
                        <Link to="/">
                            <img
                                className="w-28 md:w-40 mx-auto"
                                src={logo}
                                alt="Dhakar Pola"
                            />
                        </Link>
                    </div>

                    {/* Question */}
                    {quiz?.questions?.map((question) => {
                        return (
                            <div
                                key={question.id}
                                className={`${
                                    activeQuestion === question.id
                                        ? "block"
                                        : "hidden"
                                } transition-all duration-200`}
                            >
                                <h1 className="font-extrabold tracking-tight text-center text-3xl md:text-6xl xl:text-7xl mb-8 md:mb-10">
                                    {question.title}
                                </h1>

                                {/* Answers */}
                                <div className="col-span-6 space-y-4">
                                    {question?.options?.map((option) => {
                                        return (
                                            <div
                                                key={Math.random()}
                                                onClick={() =>
                                                    handleTotal(option.mark)
                                                }
                                                className="grid grid-cols-12 gap-2 answer"
                                            >
                                                <div className="col-span-2 bg-gray-400 text-white font-extrabold tracking-tight leading-none text-xl md:text-2xl rounded-lg p-3 md:p-5 text-center transition-all duration-300 cursor-pointer option">
                                                    {option?.serial}
                                                </div>

                                                <div className="col-span-10 bg-gray-400 text-white font-extrabold tracking-tight leading-none text-xl md:text-2xl rounded-lg p-3 md:p-5 text-center transition-all duration-300 cursor-pointer option">
                                                    {option?.title}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}

                    {/* Count */}
                    {activeQuestion <= quiz?.questions?.length && (
                        <div className="bg-primary-700 text-white rounded-full font-extrabold tracking-tight leading-none text-2xl p-5 text-center w-14 h-14 md:w-24 md:h-24 flex items-center justify-center absolute right-0 left-0 bottom-[-70px] mx-auto">
                            <div>
                                <span className="text-4xl">
                                    {activeQuestion}
                                </span>

                                <span className="font-normal">/</span>

                                <span>{quiz?.questions?.length}</span>
                            </div>
                        </div>
                    )}

                    {/* Final Result */}
                    {activeQuestion > quiz?.questions?.length && (
                        <>
                            {finalResult}
                            <div className="flex flex-col items-center justify-between mt-5">
                                <div>
                                    <button
                                        onClick={resetQuiz}
                                        type="button"
                                        className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-3 focus:outline-none"
                                    >
                                        Play again
                                    </button>
                                </div>

                                <div
                                    className="fb-share-button mt-2"
                                    data-href="https://dhakarpola.com/#/quiz"
                                    data-layout=""
                                    data-size=""
                                >
                                    <a
                                        target="_blank"
                                        href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdhakarpola.com%2F%23%2Fquiz&amp;src=sdkpreparse"
                                        className="fb-xfbml-parse-ignore text-white bg-[#1877F2] font-medium text-sm py-1 px-2 rounded flex items-center"
                                    >
                                        <i className="fa-brands fa-square-facebook text-xl mr-1"></i>
                                        Share
                                    </a>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </section>
        </>
    );
};

export default QuizDetails;
