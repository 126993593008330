import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./pages/About/About";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import Blogs from "./pages/Blogs/Blogs";
import Categories from "./pages/Categories/Categories";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import Quiz from "./pages/Quiz/Quiz";
import QuizDetails from "./pages/QuizDetails/QuizDetails";
import OborodhNaki from "./pages/OborodhNaki/OborodhNaki";

function App() {
    return (
        <HashRouter>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route
                    exact
                    path="/blogDetails/:blogId"
                    element={<BlogDetails />}
                />
                <Route
                    exact
                    path="/categories/:category"
                    element={<Categories />}
                />
                <Route exact path="/blogs" element={<Blogs />} />
                <Route exact path="/about" element={<About />} />
                <Route exact path="/quiz" element={<Quiz />} />
                <Route
                    exact
                    path="/quizDetails/:quizId"
                    element={<QuizDetails />}
                />
                <Route exact path="/oborodh-naki" element={<OborodhNaki />} />

                {/* Not Found */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </HashRouter>
    );
}

export default App;
