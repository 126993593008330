import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/Common/Layout";
import Spinner from "../../components/Common/Spinner";
import SuccessAlert from "../../components/Common/SuccessAlert";
import useBlogs from "../../hooks/useBlogs";
import Blog from "../../components/Common/Blog";

const Categories = () => {
    const [blogs, setBlogs] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(6);

    let { category } = useParams();

    const { getBlogsByCategory, isLoading, hasMoreForCategory } = useBlogs();

    const handlePage = async () => {
        setPage((prevState) => {
            return prevState + 1;
        });

        await getBlogsByCategory({
            category: category,
            page: page + 1,
            limit: limit,
        }).then((newBlogs) => {
            if (newBlogs.length > 0) {
                setBlogs(newBlogs);
            }
        });
    };

    useEffect(() => {
        setBlogs([]);
        setPage(1);

        getBlogsByCategory({
            category: category,
            page: page,
            limit: limit,
        }).then((newBlogs) => {
            if (newBlogs.length > 0) {
                setBlogs(newBlogs);
            } else {
                setBlogs("No blogs found!");
            }
        });
    }, [category]);

    return (
        <Layout>
            <section className="bg-white">
                <div className="pb-8 px-4 mx-auto max-w-screen-xl lg:pb-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
                        <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900">
                            {category}
                        </h2>

                        <p className="font-light text-gray-500 sm:text-xl">
                            A website all about Dhaka. History, Food, Culture,
                            Sports, Lifestyle, we talk about all things Dhaka.
                        </p>
                    </div>

                    {isLoading && (
                        <div className="mx-auto w-fit">
                            <Spinner />
                        </div>
                    )}

                    {blogs === "No blogs found!" ? (
                        <SuccessAlert message={blogs} />
                    ) : (
                        <div className="grid gap-8 lg:grid-cols-3 mb-8">
                            {blogs.map((blog) => {
                                if (blog?.status === "active") {
                                    return <Blog key={blog?.id} blog={blog} />;
                                }
                            })}
                        </div>
                    )}

                    {hasMoreForCategory && (
                        <div className="mx-auto w-fit">
                            <button
                                type="button"
                                className="flex items-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-3 focus:outline-none"
                                onClick={handlePage}
                            >
                                {isLoading && <Spinner />}
                                <span>Load More</span>
                            </button>
                        </div>
                    )}
                </div>
            </section>
        </Layout>
    );
};

export default Categories;
