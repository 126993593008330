import React from "react";
import Layout from "../../components/Common/Layout";
import logo from "../../images/logo.png";

const About = () => {
    return (
        <Layout>
            <section className="bg-white">
                <div className="pb-8 px-4 mx-auto max-w-screen-xl lg:pb-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
                        <div>
                            <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900">
                                About Us
                            </h2>

                            <p className="font-light text-gray-500 sm:text-xl">
                                A website all about Dhaka. History, Food,
                                Culture, Sports, Lifestyle, we talk about all
                                things Dhaka.
                            </p>
                        </div>
                    </div>

                    <div class="grid lg:gap-8 xl:gap-0 lg:grid-cols-12">
                        <div class="mr-auto flex flex-col justify-center items-center place-self-center lg:col-span-7">
                            <div>
                                <h1 class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl">
                                    Dhakar Pola
                                </h1>

                                <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl text-justify">
                                    A website all about Dhaka. History, Food,
                                    Culture, Sports, Lifestyle, we talk about
                                    all things Dhaka.
                                </p>
                            </div>
                        </div>

                        <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
                            <img className="w-3/5 ml-auto" src={logo} alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default About;
