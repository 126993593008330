import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useCategories from "../../hooks/useCategories";
import logo from "../../images/logo.png";
import Spinner from "./Spinner";

const Header = () => {
    const [active, setActive] = useState("Home");
    const [showNav, setShowNav] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [stickyClass, setStickyClass] = useState("relative");
    const [stickyLogo, setStickyLogo] = useState("hidden");
    const [navRounded, setNavRounded] = useState("rounded-2xl");

    const handleActive = (name) => {
        setActive(name);
    };

    const handleShowNav = () => {
        setShowNav((prevState) => {
            return !prevState;
        });
    };

    const handleShowDropdown = () => {
        setShowDropdown((prevState) => {
            return !prevState;
        });
    };

    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY;
            windowHeight > 50
                ? setStickyClass(
                      "fixed top-0 left-0 z-50 w-full px-4 py-4 shadow"
                  )
                : setStickyClass("relative");

            windowHeight > 50
                ? setStickyLogo("block")
                : setStickyLogo("hidden");

            windowHeight > 50
                ? setNavRounded("rounded-none")
                : setNavRounded("rounded-2xl");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", stickNavbar);

        return () => {
            window.removeEventListener("scroll", stickNavbar);
        };
    }, []);

    useEffect(() => {
        if (window.location.pathname === "/blogs") {
            setActive("Blogs");
        } else if (window.location.pathname === "/about") {
            setActive("About");
        } else if (window.location.pathname === "/quiz") {
            setActive("Quiz");
        } else if (window.location.pathname === "/oborodh-naki") {
            setActive("Oborodh Naki");
        }
    }, []);

    // Get Categories
    const [categories, setCategories] = useState([]);
    const { getAllCategories, isLoading } = useCategories();

    useEffect(() => {
        getAllCategories().then((result) => {
            setCategories(result);
        });
    }, []);

    return (
        <section className="bg-white">
            <div className="py-4 lg:py-8 px-4 mx-auto max-w-screen-xl lg:pb-10 lg:px-6">
                <div className="hidden lg:flex items-center justify-between mb-8 bg-secondary-700 rounded-2xl p-5">
                    <div>
                        <a
                            className="text-xl text-gray-700 hover:text-primary-700 mr-5"
                            href="https://www.facebook.com/Dhakarpola.vvs"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="fa-brands fa-facebook-f"></i>
                        </a>

                        <a
                            className="text-xl text-gray-700 hover:text-primary-700 mr-5"
                            href="https://www.instagram.com/dhakarpolavvs/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="fa-brands fa-instagram"></i>
                        </a>

                        <a
                            className="text-xl text-gray-700 hover:text-primary-700"
                            href="https://twitter.com/DhakarPolaVVS"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="fa-brands fa-twitter"></i>
                        </a>
                    </div>

                    <div>
                        <Link to="/">
                            <img className="w-36 mr-14" src={logo} alt="" />
                        </Link>
                    </div>

                    <div>
                        <a
                            className="text-xl text-gray-700 hover:text-primary-700"
                            href="https://dhakar-pola-test.web.app/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="fa-solid fa-user-lock"></i>
                        </a>
                    </div>
                </div>

                {/* Navbar */}
                <div
                    className={`header-section transition-all duration-500 ease-in-out bg-secondary-700 p-5 lg:py-0 lg:rounded-none lg:bg-white ${stickyClass} ${navRounded}`}
                >
                    <nav>
                        <div className="flex flex-wrap items-center justify-between lg:justify-center mx-auto">
                            <Link
                                to="/"
                                className="flex items-center lg:hidden"
                            >
                                <img
                                    src={logo}
                                    className="w-32 md:w-36 mx-auto"
                                    alt="logo"
                                />
                            </Link>

                            <div className="flex lg:order-2">
                                <button
                                    data-collapse-toggle="navbar-sticky"
                                    type="button"
                                    className="inline-flex items-center p-2 text-sm text-gray-500 bg-gray-100 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                                    aria-controls="navbar-sticky"
                                    aria-expanded="false"
                                    onClick={handleShowNav}
                                >
                                    <span className="sr-only">
                                        Open main menu
                                    </span>

                                    <svg
                                        className="w-6 h-6"
                                        aria-hidden="true"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </button>
                            </div>

                            {/* For Web */}
                            <div className="hidden lg:block">
                                <div className={`${stickyLogo}`}>
                                    <Link to="/" className="flex items-center">
                                        <img
                                            src={logo}
                                            className="w-32 md:w-36 mx-auto"
                                            alt="logo"
                                        />
                                    </Link>
                                </div>

                                <div
                                    className={`items-center justify-between w-full lg:flex lg:w-auto lg:order-1 ${
                                        showNav ? "block" : "hidden"
                                    }`}
                                    id="navbar-sticky"
                                >
                                    <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 lg:flex-row lg:space-x-8 lg:mt-0 lg:text-sm lg:font-medium lg:border-0 lg:bg-transparent">
                                        <li
                                            className="mb-3 lg:mb-0"
                                            onClick={() => handleActive("Home")}
                                        >
                                            <Link
                                                to="/"
                                                className={`block py-2 pl-3 pr-4 rounded lg:p-0 font-semibold ${
                                                    active === "Home"
                                                        ? "lg:text-primary bg-primary lg:bg-transparent text-primary-700"
                                                        : "lg:text-gray-700 text-primary"
                                                }`}
                                            >
                                                Home
                                            </Link>
                                        </li>

                                        <li
                                            className="mb-3 lg:mb-0 relative"
                                            onClick={() =>
                                                handleActive("Categories")
                                            }
                                        >
                                            <button
                                                id="dropdownNavbarLink"
                                                data-dropdown-toggle="dropdownNavbar"
                                                className={`py-2 pl-3 pr-4 rounded lg:p-0 font-semibold flex items-center ${
                                                    active === "Categories"
                                                        ? "lg:text-primary bg-primary lg:bg-transparent text-primary-700"
                                                        : "lg:text-gray-700 text-primary"
                                                }`}
                                                onClick={handleShowDropdown}
                                            >
                                                Categories{" "}
                                                <svg
                                                    className="w-5 h-5 ml-1"
                                                    aria-hidden="true"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                            </button>

                                            <div
                                                id="dropdownNavbar"
                                                className={`${
                                                    showDropdown ? "" : "hidden"
                                                } z-10 font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 absolute top-10`}
                                            >
                                                <ul
                                                    className="py-2 text-sm text-gray-700"
                                                    aria-labelledby="dropdownLargeButton"
                                                >
                                                    {isLoading && (
                                                        <div className="mx-auto w-fit">
                                                            <Spinner />
                                                        </div>
                                                    )}

                                                    {categories.map(
                                                        (category) => {
                                                            return (
                                                                <li
                                                                    key={
                                                                        category?.id
                                                                    }
                                                                >
                                                                    <Link
                                                                        to={`/categories/${category?.title}`}
                                                                        className="block px-4 py-2 hover:bg-gray-100"
                                                                    >
                                                                        {
                                                                            category?.title
                                                                        }
                                                                    </Link>
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ul>
                                            </div>
                                        </li>

                                        <li
                                            className="mb-3 lg:mb-0"
                                            onClick={() =>
                                                handleActive("Blogs")
                                            }
                                        >
                                            <Link
                                                to="/blogs"
                                                className={`block py-2 pl-3 pr-4 rounded lg:p-0 font-semibold ${
                                                    active === "Blogs"
                                                        ? "lg:text-primary bg-primary lg:bg-transparent text-primary-700"
                                                        : "lg:text-gray-700 text-primary"
                                                }`}
                                            >
                                                Blogs
                                            </Link>
                                        </li>

                                        <li
                                            className="mb-3 lg:mb-0"
                                            onClick={() => handleActive("Quiz")}
                                        >
                                            <Link
                                                to="/quiz"
                                                className={`block py-2 pl-3 pr-4 rounded lg:p-0 font-semibold ${
                                                    active === "Quiz"
                                                        ? "lg:text-primary bg-primary lg:bg-transparent text-primary-700"
                                                        : "lg:text-gray-700 text-primary"
                                                }`}
                                            >
                                                Quiz
                                            </Link>
                                        </li>

                                        <li
                                            className="mb-3 lg:mb-0"
                                            onClick={() =>
                                                handleActive("About")
                                            }
                                        >
                                            <Link
                                                to="/about"
                                                className={`block py-2 pl-3 pr-4 rounded lg:p-0 font-semibold ${
                                                    active === "About"
                                                        ? "lg:text-primary bg-primary lg:bg-transparent text-primary-700"
                                                        : "lg:text-gray-700 text-primary"
                                                }`}
                                            >
                                                About
                                            </Link>
                                        </li>

                                        <li
                                            className="mb-3 lg:mb-0"
                                            onClick={() =>
                                                handleActive("Oborodh Naki")
                                            }
                                        >
                                            <Link
                                                to="/oborodh-naki"
                                                className={`block py-2 pl-3 pr-4 rounded lg:p-0 font-semibold ${
                                                    active === "Oborodh Naki"
                                                        ? "lg:text-primary bg-primary lg:bg-transparent text-primary-700"
                                                        : "lg:text-gray-700 text-primary"
                                                }`}
                                            >
                                                Oborodh Naki
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* For Mobile And Tab */}
                            <div
                                className={`block lg:hidden items-center justify-between w-full lg:flex lg:w-auto lg:order-1 ${
                                    showNav ? "block" : "hidden"
                                }`}
                                id="navbar-sticky"
                            >
                                <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 lg:flex-row lg:space-x-8 lg:mt-0 lg:text-sm lg:font-medium lg:border-0 lg:bg-transparent">
                                    <li
                                        className="mb-3 lg:mb-0"
                                        onClick={() => handleActive("Home")}
                                    >
                                        <Link
                                            to="/"
                                            className={`block py-2 pl-3 pr-4 rounded lg:p-0 font-semibold ${
                                                active === "Home"
                                                    ? "lg:text-primary bg-primary lg:bg-transparent text-primary-700"
                                                    : "lg:text-gray-700 text-primary"
                                            }`}
                                        >
                                            Home
                                        </Link>
                                    </li>

                                    <li
                                        className="mb-3 lg:mb-0 relative"
                                        onClick={() =>
                                            handleActive("Categories")
                                        }
                                    >
                                        <button
                                            id="dropdownNavbarLink"
                                            data-dropdown-toggle="dropdownNavbar"
                                            className={`py-2 pl-3 pr-4 rounded lg:p-0 font-semibold flex items-center ${
                                                active === "Categories"
                                                    ? "lg:text-primary bg-primary lg:bg-transparent text-primary-700"
                                                    : "lg:text-gray-700 text-primary"
                                            }`}
                                            onClick={handleShowDropdown}
                                        >
                                            Categories{" "}
                                            <svg
                                                className="w-5 h-5 ml-1"
                                                aria-hidden="true"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                        </button>

                                        <div
                                            id="dropdownNavbar"
                                            className={`${
                                                showDropdown ? "" : "hidden"
                                            } z-10 font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 absolute top-10`}
                                        >
                                            <ul
                                                className="py-2 text-sm text-gray-700"
                                                aria-labelledby="dropdownLargeButton"
                                            >
                                                {isLoading && (
                                                    <div className="mx-auto w-fit">
                                                        <Spinner />
                                                    </div>
                                                )}

                                                {categories.map((category) => {
                                                    return (
                                                        <li key={category?.id}>
                                                            <Link
                                                                to={`/categories/${category?.title}`}
                                                                className="block px-4 py-2 hover:bg-gray-100"
                                                            >
                                                                {
                                                                    category?.title
                                                                }
                                                            </Link>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </li>

                                    <li
                                        className="mb-3 lg:mb-0"
                                        onClick={() => handleActive("Blogs")}
                                    >
                                        <Link
                                            to="/blogs"
                                            className={`block py-2 pl-3 pr-4 rounded lg:p-0 font-semibold ${
                                                active === "Blogs"
                                                    ? "lg:text-primary bg-primary lg:bg-transparent text-primary-700"
                                                    : "lg:text-gray-700 text-primary"
                                            }`}
                                        >
                                            Blogs
                                        </Link>
                                    </li>

                                    <li
                                        className="mb-3 lg:mb-0"
                                        onClick={() => handleActive("Quiz")}
                                    >
                                        <Link
                                            to="/quiz"
                                            className={`block py-2 pl-3 pr-4 rounded lg:p-0 font-semibold ${
                                                active === "Quiz"
                                                    ? "lg:text-primary bg-primary lg:bg-transparent text-primary-700"
                                                    : "lg:text-gray-700 text-primary"
                                            }`}
                                        >
                                            Quiz
                                        </Link>
                                    </li>

                                    <li
                                        className="mb-3 lg:mb-0"
                                        onClick={() => handleActive("About")}
                                    >
                                        <Link
                                            to="/about"
                                            className={`block py-2 pl-3 pr-4 rounded lg:p-0 font-semibold ${
                                                active === "About"
                                                    ? "lg:text-primary bg-primary lg:bg-transparent text-primary-700"
                                                    : "lg:text-gray-700 text-primary"
                                            }`}
                                        >
                                            About
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </section>
    );
};

export default Header;
