import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Common/Layout";
import Spinner from "../../components/Common/Spinner";
import formatDate from "../../helpers/formatDate";
import toUniqueArr from "../../helpers/toUniqueArr";
import useBlogs from "../../hooks/useBlogs";
import Blog from "../../components/Common/Blog";

// Popular Blogs Images
import blog40Image from "../../images/blogs/40.jfif";
import blog36Image from "../../images/blogs/36.jfif";
import blog31Image from "../../images/blogs/31.jpg";
import blog24Image from "../../images/blogs/24.jfif";
import blog16Image from "../../images/blogs/16.jfif";
import blog14Image from "../../images/blogs/14.jfif";
import defaultUserImage from "../../images/default-user-img.jfif";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Home = () => {
    // Get Blogs
    const [blogs, setBlogs] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(6);
    const { getBlogs, getLastBlog, isLoading, hasMore } = useBlogs();

    const handlePage = () => {
        setPage((prevState) => {
            return prevState - limit;
        });
    };

    useEffect(() => {
        getLastBlog().then((lastBlog) => {
            if (lastBlog.id) {
                setPage(lastBlog.id);
            }
        });
    }, []);

    useEffect(() => {
        getBlogs({ page: page, limit: limit }).then((newBlogs) => {
            if (newBlogs.length > 0) {
                setBlogs(toUniqueArr([...blogs, ...newBlogs]));
            }
        });
    }, [page]);

    // Videos
    const [videos, setVideos] = useState([
        "https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FDhakarpola.vvs%2Fvideos%2F624927239486217%2F&show_text=false&width=476&t=0",

        "https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FDhakarpola.vvs%2Fvideos%2F147274168241730%2F&show_text=true&width=476&t=0",

        "https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FDhakarpola.vvs%2Fvideos%2F185842777684344%2F&show_text=false&width=476&t=0",

        "https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FDhakarpola.vvs%2Fvideos%2F935796744506899%2F&show_text=false&width=476&t=0",

        "https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FDhakarpola.vvs%2Fvideos%2F188868440364475%2F&show_text=false&width=476&t=0",

        "https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FDhakarpola.vvs%2Fvideos%2F946684739847987%2F&show_text=false&width=476&t=0",

        "https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FDhakarpola.vvs%2Fvideos%2F2836371133163677%2F&show_text=false&width=476&t=0",

        "https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FDhakarpola.vvs%2Fvideos%2F3504684859815147%2F&show_text=false&width=267&t=0",
    ]);

    return (
        <Layout>
            {/* Videos */}
            <section className="bg-white">
                <div className="pb-8 px-4 mx-auto max-w-screen-xl lg:pb-16 lg:px-6">
                    {/* For Web */}
                    <div className="hidden lg:block">
                        <Swiper
                            initialSlide={1}
                            spaceBetween={30}
                            centeredSlides={true}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                            slidesPerView={3}
                            grabCursor={true}
                        >
                            {videos.map((video) => {
                                return (
                                    <SwiperSlide>
                                        <iframe
                                            className="rounded-2xl w-full h-[340px] md:h-[376px]"
                                            src={video}
                                            style={{
                                                border: "none",
                                                overflow: "hidden",
                                            }}
                                            scrolling="no"
                                            frameBorder="0"
                                            allowFullScreen={true}
                                            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                        ></iframe>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>

                    {/* For Tab */}
                    <div className="hidden md:block lg:hidden">
                        <Swiper
                            initialSlide={1}
                            spaceBetween={30}
                            centeredSlides={true}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                            slidesPerView={2}
                            grabCursor={true}
                        >
                            {videos.map((video) => {
                                return (
                                    <SwiperSlide>
                                        <iframe
                                            className="rounded-2xl w-full h-[340px] md:h-[376px]"
                                            src={video}
                                            style={{
                                                border: "none",
                                                overflow: "hidden",
                                            }}
                                            scrolling="no"
                                            frameBorder="0"
                                            allowFullScreen={true}
                                            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                        ></iframe>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>

                    {/* For Mobile */}
                    <div className="block md:hidden">
                        <Swiper
                            initialSlide={1}
                            spaceBetween={30}
                            centeredSlides={true}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                            slidesPerView={1}
                            grabCursor={true}
                        >
                            {videos.map((video) => {
                                return (
                                    <SwiperSlide>
                                        <iframe
                                            className="rounded-2xl w-full h-[340px] md:h-[376px]"
                                            src={video}
                                            style={{
                                                border: "none",
                                                overflow: "hidden",
                                            }}
                                            scrolling="no"
                                            frameBorder="0"
                                            allowFullScreen={true}
                                            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                        ></iframe>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                </div>
            </section>

            {/* Popular Blogs */}
            <section className="bg-white">
                <div className="pb-8 px-4 mx-auto max-w-screen-xl lg:pb-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
                        <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900">
                            Popular Blogs
                        </h2>

                        <p className="font-light text-gray-500 sm:text-xl">
                            A website all about Dhaka. History, Food, Culture,
                            Sports, Lifestyle, we talk about all things Dhaka.
                        </p>
                    </div>

                    <div className="grid gap-8 lg:grid-cols-3 mb-8">
                        <Blog 
                            key={40} 
                            blog={{
                                id: 40, 
                                title: "সামার ভ্যাকেশন ডেস্টিনেশন", 
                                description: "বিগত কয়েকদিনে ঢাকা সহ দেশের প্রায় সব অঞ্চলেই চলছে গ্রীষ্মের গরমের", 
                                thumbnail: blog40Image, 
                                category: {title: "Lifestyle"}, 
                                createdAt: "13 May, 2023",
                                author: {image: defaultUserImage, firstName: "Hasin Muhammad"}
                            }} 
                        />

                        <Blog 
                            key={36} 
                            blog={{
                                id: 36, 
                                title: "হিম উৎসব", 
                                description: "বাংলা লোকজ সংস্কৃতি এবং শিল্পের বাণিজ্যিকীকরণ রক্ষার উদ্দেশ্যে শুরু", 
                                thumbnail: blog36Image, 
                                category: {title: "Culture"}, 
                                createdAt: "11 May, 2023",
                                author: {image: defaultUserImage, firstName: "Hasin Muhammad"}
                            }} 
                        />

                        <Blog 
                            key={31} 
                            blog={{
                                id: 31, 
                                title: "বিশ্ব কবিতা দিবস", 
                                description: "লিপি আবিষ্কারের অনেক আগে থেকেই বিশ্বের সব প্রাচীন সভ্যতার নিদর", 
                                thumbnail: blog31Image, 
                                category: {title: "Culture"}, 
                                createdAt: "11 May, 2023",
                                author: {image: defaultUserImage, firstName: "Mehedi Hasan"}
                            }} 
                        />

                        <Blog 
                            key={24} 
                            blog={{
                                id: 24, 
                                title: "ঢাকার পোষা পাখির হাট", 
                                description: "শখের দাম লাখ টাকা ছোটকাল থেকেই এই প্রবাদটি আমরা সবাই শুনে এসেছি।", 
                                thumbnail: blog24Image, 
                                category: {title: "Culture"}, 
                                createdAt: "11 May, 2023",
                                author: {image: defaultUserImage, firstName: "Mehedi Hasan"}
                            }} 
                        />

                        <Blog 
                            key={16} 
                            blog={{
                                id: 16, 
                                title: "শীতে ঢাকার কনভেনশন হল", 
                                description: "ঢাকার শীতকাল মানেই শুধু ঠান্ডা, কুয়াশা, ও মলিন আবহাওয়া নয়, এর সাথে", 
                                thumbnail: blog16Image, 
                                category: {title: "Lifestyle"}, 
                                createdAt: "11 May, 2023",
                                author: {image: defaultUserImage, firstName: "Hasin Muhammad Abrar"}
                            }} 
                        />

                        <Blog 
                            key={14} 
                            blog={{
                                id: 14, 
                                title: "ঢাকার ফুটবল মাঠ", 
                                description: "শহরের ব্যাস্ত জীবনের মধ্যে খেলাধুলা বিষয়টি যেন আমাদের মাঝ থেকে হারি", 
                                thumbnail: blog14Image, 
                                category: {title: "Sports"}, 
                                createdAt: "11 May, 2023",
                                author: {image: defaultUserImage, firstName: "Hasin Muhammad Abrar"}
                            }} 
                        />
                    </div>
                </div>
            </section>

            {/* Hero Section */}
            <section className="bg-white">
                <div className="pb-8 px-4 mx-auto max-w-screen-xl lg:pb-16 lg:px-6">
                    {isLoading ? (
                        <div className="mx-auto w-fit">
                            <Spinner />
                        </div>
                    ) : (
                        <Link to={`/blogDetails/${blogs[0]?.id}`}>
                            <div className="relative">
                                <img
                                    className="w-full rounded-2xl"
                                    src={blogs[0]?.coverImage}
                                    alt=""
                                />

                                <div
                                    className="absolute top-0 left-0 w-full rounded-2xl"
                                    style={{
                                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                                    }}
                                >
                                    <img
                                        className="w-full invisible"
                                        src={blogs[0]?.coverImage}
                                        alt=""
                                    />
                                </div>

                                <div className="absolute top-0 bottom-0 right-0 left-0 m-auto h-fit w-fit">
                                    <header className="mb-4 lg:mb-6 not-format flex flex-col items-center">
                                        <span className="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded w-fit mb-2">
                                            <svg
                                                className="mr-1 w-3 h-3"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
                                            </svg>

                                            {blogs[0]?.category?.title}
                                        </span>

                                        <h1 className="text-xl md:text-3xl font-extrabold leading-tight text-gray-100 lg:text-4xl mb-1 text-center">
                                            {blogs[0]?.title}
                                        </h1>

                                        <span className="text-sm text-gray-100 font-bold">
                                            {formatDate(blogs[0]?.createdAt)}
                                        </span>
                                    </header>
                                </div>
                            </div>
                        </Link>
                    )}
                </div>
            </section>

            {/* Latest Blogs */}
            <section className="bg-white">
                <div className="pb-8 px-4 mx-auto max-w-screen-xl lg:pb-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
                        <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900">
                            Our Blogs
                        </h2>

                        <p className="font-light text-gray-500 sm:text-xl">
                            A website all about Dhaka. History, Food, Culture,
                            Sports, Lifestyle, we talk about all things Dhaka.
                        </p>
                    </div>

                    {isLoading && (
                        <div className="mx-auto w-fit">
                            <Spinner />
                        </div>
                    )}

                    <div className="grid gap-8 lg:grid-cols-3 mb-8">
                        {blogs.map((blog) => {
                            if (blog?.status === "active") {
                                return <Blog key={blog?.id} blog={blog} />;
                            }
                        })}
                    </div>

                    {hasMore && (
                        <div className="mx-auto w-fit">
                            <button
                                type="button"
                                className="flex items-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-3 focus:outline-none"
                                onClick={handlePage}
                            >
                                {isLoading && <Spinner />}
                                <span>Load More</span>
                            </button>
                        </div>
                    )}
                </div>
            </section>
        </Layout>
    );
};

export default Home;
