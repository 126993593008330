import React, { useState } from "react";

const OborodhNaki = () => {
    const [isHoveredRed, setIsHoveredRed] = useState(false);
    const [isHoveredGreen, setIsHoveredGreen] = useState(false);

    const hoverStyle = {
        transition: "transform 0.3s ease",
    };

    const onHoverRed = {
        transform: "scale(1.1)",
    };

    const onHoverGreen = {
        transform: "scale(1.1)",
    };

    return (
        <>
            <div className="flex flex-row justify-center align-center bg-[#B2BEB5] text-3xl font-bold py-10 text-center">
                <i>IS TOMORROW OBORODH?</i>
            </div>
            <div
                className="flex flex-col justify-center align-center bg-[#FF0000] md:text-5xl text-xl font-bold md:mt-48 mt-5 md:mx-56 md:py-20 rounded-lg shadow-lg text-center"
                style={
                    isHoveredRed ? { ...hoverStyle, ...onHoverRed } : hoverStyle
                }
                onMouseEnter={() => setIsHoveredRed(true)}
                onMouseLeave={() => setIsHoveredRed(false)}
            >
                <p className="flex justify-center align-center text-white">
                    YES
                </p>
                <p className="flex justify-center align-center py-5">
                    Tomorrow, 8th November is oborodh.
                </p>
            </div>
            <div className="flex justify-center align-center bg-[#B2BEB5] text-3xl font-bold py-10 mt-12 text-center">
                <i>IS TOMORROW HORTAL?</i>
            </div>
            <div
                className="flex flex-col justify-center align-center bg-[#08fa6d] md:text-5xl text-xl font-bold md:mt-48 mt-5 md:mx-56 md:py-20 rounded-lg shadow-lg text-center"
                style={
                    isHoveredGreen
                        ? { ...hoverStyle, ...onHoverGreen }
                        : hoverStyle
                }
                onMouseEnter={() => setIsHoveredGreen(true)}
                onMouseLeave={() => setIsHoveredGreen(false)}
            >
                <p className="flex justify-center align-center text-white">
                    NO
                </p>
                <p className="flex justify-center align-center py-5">
                    Tomorrow, 8th November is not hortal.
                </p>
            </div>
            <div className="flex justify-center align-center text-xl font-bold bottom-0 my-10">
                <i>This is not a news portal</i>
            </div>
        </>
    );
};

export default OborodhNaki;
