import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useCategories from "../../hooks/useCategories";
import logo from "../../images/logo.png";
import Spinner from "./Spinner";
import SuccessAlert from "./SuccessAlert";
import ErrorAlert from "./ErrorAlert";

const Footer = () => {
    // Get Categories
    const [categories, setCategories] = useState([]);
    const { getAllCategories, isLoading } = useCategories();

    useEffect(() => {
        getAllCategories().then((result) => {
            setCategories(result);
        });
    }, []);

    // For Newsletter
    const [newsLetterIsLoading, setNewsletterIsLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const handleNewsletter = (e) => {
        e.preventDefault();
        setNewsletterIsLoading(true);

        const data = new FormData(e.target);

        fetch("https://formspree.io/f/xlekpjbr", {
            method: e.target.method,
            body: data,
            headers: {
                Accept: "application/json",
            },
        })
            .then((response) => {
                if (response.ok) {
                    e.target.reset();
                    setNewsletterIsLoading(false);
                    setSuccess("Thank you for subscribe");
                } else {
                    response.json().then((data) => {
                        if (Object.hasOwn(data, "errors")) {
                            setError(data);
                            setNewsletterIsLoading(false);
                        } else {
                            setError(data);
                            setNewsletterIsLoading(false);
                        }
                    });
                }
            })
            .catch((error) => {
                setError(error);
                setNewsletterIsLoading(false);
            });

        setTimeout(() => {
            setSuccess("");
            setError("");
        }, [3000]);
    };

    return (
        <section className="bg-gray-900 relative">
            <div className="py-8 px-4 md:px-8 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <div>
                        <div className="pb-4">
                            <Link to="/">
                                <img
                                    className="w-48 mx-auto md:mx-0"
                                    src={logo}
                                    alt="logo"
                                />
                            </Link>
                        </div>

                        <p className="normal-text text-white pb-4 text-center md:text-start">
                            A website all about Dhaka. History, Food, Culture,
                            Sports, Lifestyle, we talk about all things Dhaka.
                        </p>

                        <div className="flex items-center pb-4 justify-center md:justify-start">
                            <i className="fa-sharp fa-solid fa-location-dot text-white text-2xl pr-2"></i>

                            <p className="text-white normal-text">
                                Dhaka, Bangladesh
                            </p>
                        </div>

                        <div className="text-center md:text-start">
                            <a
                                href="https://www.facebook.com/Dhakarpola.vvs"
                                target="_blank"
                            >
                                <i className="fa-brands fa-facebook text-white text-2xl pr-4 hover:text-primary-700"></i>
                            </a>

                            <a
                                href="https://www.instagram.com/dhakarpolavvs//"
                                target="_blank"
                            >
                                <i className="fa-brands fa-instagram text-white text-2xl pr-4 hover:text-primary-700"></i>
                            </a>

                            <a
                                href="https://www.youtube.com/@dhakarpola22/featured"
                                target="_blank"
                            >
                                <i className="fa-brands fa-youtube text-white text-2xl pr-4 hover:text-primary-700"></i>
                            </a>
                        </div>
                    </div>

                    <div className="text-center md:text-start">
                        <div className="pb-4">
                            <h3 className="text-white text-2xl font-semibold">
                                Categories
                            </h3>
                        </div>

                        <div>
                            {isLoading && (
                                <div className="mx-auto lg:mx-0 w-fit">
                                    <Spinner />
                                </div>
                            )}

                            {categories.slice(0, 5).map((category) => {
                                return (
                                    <Link
                                        key={category?.id}
                                        to={`/categories/${category?.title}`}
                                        className="text-white hover:text-primary-700 normal-text block pb-5"
                                    >
                                        <i className="fa-solid fa-caret-right pr-2"></i>
                                        <span>{category?.title}</span>
                                    </Link>
                                );
                            })}
                        </div>
                    </div>

                    <div className="text-center md:text-start">
                        <div className="pb-4">
                            <h3 className="text-white text-2xl font-semibold">
                                Quick Links
                            </h3>
                        </div>

                        <div>
                            <Link
                                to="/blogs"
                                className="text-white hover:text-primary-700 normal-text block pb-5"
                            >
                                <i className="fa-solid fa-caret-right pr-2"></i>
                                <span>Blogs</span>
                            </Link>

                            <Link
                                to="/about"
                                className="text-white hover:text-primary-700 normal-text block pb-5"
                            >
                                <i className="fa-solid fa-caret-right pr-2"></i>
                                <span>About</span>
                            </Link>

                            {categories.slice(5, 8).map((category) => {
                                return (
                                    <Link
                                        key={category?.id}
                                        to={`/categories/${category?.title}`}
                                        className="text-white hover:text-primary-700 normal-text block pb-5"
                                    >
                                        <i className="fa-solid fa-caret-right pr-2"></i>
                                        <span>{category?.title}</span>
                                    </Link>
                                );
                            })}
                        </div>
                    </div>

                    <div className="text-center md:text-start">
                        <div className="pb-4">
                            <h3 className="text-white text-2xl font-semibold">
                                Newsletter
                            </h3>
                        </div>

                        <form
                            action="https://formspree.io/f/xlekpjbr"
                            method="POST"
                            onSubmit={handleNewsletter}
                        >
                            <input
                                name="email"
                                type="email"
                                className="bg-transparent placeholder-white border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-700 focus:border-primary-700 block w-full px-5 py-3.5 mb-2"
                                placeholder="Your Email Address"
                                required
                            />
                            
                            <button
                                type="submit"
                                className="text-white w-full bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-3.5 focus:outline-none flex justify-center"
                                disabled={newsLetterIsLoading}
                            >
                                {newsLetterIsLoading ? (
                                    <Spinner />
                                ) : (
                                    "Subscribe"
                                )}
                            </button>

                            {success && (
                                <div className="absolute bottom-0 right-0">
                                    <SuccessAlert message={success} />
                                </div>
                            )}
                            {error && (
                                <div className="absolute bottom-0 right-0">
                                    {<ErrorAlert message={error} />}
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>

            <div className="bg-black normal-text text-white text-center p-5 md:p-10">
                Developed By AWSDYN. All Rights Reserved
            </div>
        </section>
    );
};

export default Footer;
