import React from "react";
import Layout from "../../components/Common/Layout";
import { Link } from "react-router-dom";

const Quiz = () => {
    const quizDb = require("../../quizDb/quizDb.json");

    return (
        <Layout>
            <section className="bg-white bangla-font">
                <div className="pb-8 px-4 mx-auto max-w-screen-xl lg:pb-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
                        <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900">
                            Dhakar Quiz
                        </h2>

                        <p className="font-light text-gray-500 sm:text-xl">
                            Fuel Your Curiosity with our Exciting Quiz!
                        </p>
                    </div>

                    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                        {quizDb.map((quiz) => {
                            return (
                                <article
                                    key={quiz?.id}
                                    className="bg-white rounded-2xl border border-gray-200 shadow-md"
                                >
                                    <Link to={`/quizDetails/${quiz?.id}`}>
                                        <img
                                            className="w-full rounded-2xl"
                                            src={quiz?.thumbnail}
                                            alt="Dhakar Pola"
                                        />
                                    </Link>

                                    <div className="p-6">
                                        <div className="flex justify-between items-center mb-5 text-gray-500">
                                            <span className="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded">
                                                <svg
                                                    className="mr-1 w-3 h-3"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
                                                </svg>
                                                Dhakar Quiz
                                            </span>

                                            <span className="text-sm">
                                                {quiz?.createdAt}
                                            </span>
                                        </div>

                                        <h2 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                                            <Link
                                                to={`/quizDetails/${quiz?.id}`}
                                            >
                                                {quiz?.title}
                                            </Link>
                                        </h2>

                                        <Link
                                            to={`/quizDetails/${quiz?.id}`}
                                            className="text-white w-full bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-3 focus:outline-none flex justify-center mt-5"
                                        >
                                            Play Now
                                        </Link>
                                    </div>
                                </article>
                            );
                        })}
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Quiz;
